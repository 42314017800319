import { postNoCatch } from '@api/request';
import topic from '../topic';

export const rfidAPI = {
  open_card: `${topic.rfidTopic}/open_card`, // 开卡
  get_rfids: `${topic.rfidTopic}/get_rfids`, // 获取开卡列表
  destroy_rfid_by_ids: `${topic.rfidTopic}/destroy_rfid_by_ids`, // 删除
  get_rfid_by_id: `${topic.rfidTopic}/get_rfid_by_id`, // 通过id获取
  edit_rfid: `${topic.rfidTopic}/edit_rfid`, // 修改
  read_card: `${topic.rfidTopic}/read_card`, // 读卡
  open_edit: `${topic.rfidTopic}/open_edit`, // 开版
  edit_open: `${topic.rfidTopic}/edit_open`, // 开版 修改

  edit_stpr: `${topic.rfidTopic}/edit_stpr`, // 备料
  edit_comp: `${topic.rfidTopic}/edit_comp`, // 复合
  edit_lase: `${topic.rfidTopic}/edit_lase`, // 激光
  edit_stam: `${topic.rfidTopic}/edit_stam`, // 印花
  edit_embr: `${topic.rfidTopic}/edit_embr`, // 电绣

  edit_mase: `${topic.rfidTopic}/edit_mase`, // 机缝
  edit_manu: `${topic.rfidTopic}/edit_manu`, // 手工
  edit_insp: `${topic.rfidTopic}/edit_insp`, // 检验
  edit_emcd: `${topic.rfidTopic}/edit_emcd`, // 制卡
  get_rfid_by_smpl_no: `${topic.rfidTopic}/get_rfid_by_smpl_no`, // 根据样品编号获取RFID

  deleteMtrlListById: id => postNoCatch(`${topic.rfidTopic}/deleteMtrListOne/${id}`)
};
